@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.homeNav {
  position: absolute;
  top: 0;
  left: 250px;
  /* width: 100%; */
  width: calc(100% - 250px);
  /* background-color: var(--sidebar-color); */
 background: var(--black-color);
  transition: var(--tran-05);
}
.homeNav .text {
  font-size: 30px;
  font-weight: 500;
  color: var(--text-color);
  padding: 12px 60px;
}

.sidebar.close ~ .homeNav {
  left: 78px;
  height: 100%;
  width: calc(100% - 78px);
}

.mainLoader{
  position : fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: #f1f1f1;
}

/* Customize scrollbar thumb (the draggable part) */
::-webkit-scrollbar-thumb {
  /* background-color: #888; */
  /* background-color: white; */
  /* background-color: rgba(255, 138, 0, 1); */
  /* background-color: #cda45e; */
  /* background-color: #D4AF37; */
  background-color: #dfaaa9;
  border-radius: 15px;
}

/* Customize scrollbar on hover */
::-webkit-scrollbar-thumb:hover {
  /* background-color: #555; */
   /* background-color: white; */
  /* background-color: rgba(255, 138, 0, 1); */
  background-color: #dfaaa9;
}