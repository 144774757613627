.content-field {
    /* background-color: #f9f9f9; */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
}

.preview {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
}

.remove-btn {
    margin-top: 15px;
}
