.admin-panel-container {
  /* height: 100vh; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  padding: 0 10% 0 10%;
}

.admin-panel-card {
  padding: 30px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  transition: transform 0.3s;
  cursor: pointer;
}

.admin-panel-card:hover {
  transform: scale(1.05);
}

.admin-panel-icon {
  font-size: 40px;
  color: white;
  margin-bottom: 10px;
}
